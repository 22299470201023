import React, { useEffect, useState } from "react";
import "./Counter.css";
import Navbar from "./common/Navbar";
import { styles } from "../helpers/styles";
import { useModalProvider } from "./providers/ModalProvider";
import { db } from "./common/firebaseConfig";
import { ref, set } from "firebase/database";
import { useUserProvider } from "./providers/UserProvider";

export default function Counter() {
  const { user } = useUserProvider();
  const { setModalOpen } = useModalProvider();
  const blessingCount = parseInt(user?.blessingCount || 0);

  const updateBlessingCount = (newCount) => {
    const blessingRef = ref(db, "users/" + user?.userId + "/blessingCount");
    set(blessingRef, newCount)
      .then(() => {
        console.log("success");
      })
      .catch((e) => console.log({ e }));
  };
  const counterFunction = () => {
    const newCount = blessingCount + 1;
    alert("The number of blessings I have is " + newCount);
    updateBlessingCount(blessingCount + 1);
  };

  const resetCounter = () => {
    alert("time to make new blessings");
    updateBlessingCount(0);
  };
  return (
    <div>
      {/* <button onClick={() => props.goBack()} style={styles}>
        Go Home
      </button> */}
      <Navbar />
      <br />
      <br />
      {!!user && (
        <div>
          <button style={styles} onClick={counterFunction}>
            Count a blessing! + {blessingCount}
          </button>
          <button onClick={resetCounter}>reset my blessings</button>
          <h1>{blessingCount}</h1>
        </div>
      )}
      {!user && (
        <button onClick={() => setModalOpen(true)}>Please Login</button>
      )}
    </div>
  );
}
