import moment from "moment";
import React, { useState, useEffect } from "react";
import { useRecordingProvider } from "./providers/RecordingProvider";

const RoutinesTimer = () => {
  const { isActive, setIsActive, toggle, seconds, setSeconds, reset } =
    useRecordingProvider();

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="app">
      {isActive ? (
        <div className="time">{moment.utc(seconds * 1000).format("mm:ss")}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RoutinesTimer;
