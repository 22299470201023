//import Text Entry Helper Files
import {
  handleNewTextEntry,
  handleTextEntrySubmit,
} from "../helpers/textEntryHelpers";
import { useState } from "react";
import { useUserProvider } from "./providers/UserProvider";
import { useRoutinesProvider } from "./providers/RoutinesProvider";
import "./MindMap.scss";
import { useSearchParams } from "react-router-dom";

export default function RoutinesTextEntry() {
  const {
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
    newTextEntry,
    setNewTextEntry,
    morningPromptRef,
    eveningPromptRef,
    promptCounter,
    nextPrompt,
    setMadeSelection,
    toggle,
    morningSheetsIdRef,
    eveningSheetsIdRef,
    morningSurvey,
    eveningSurvey,
  } = useRoutinesProvider();

  let sheetsIdRef = [];
  let survey = [];
  let promptRef = [];
  if (window.location.pathname === "/routines-morning-prompts") {
    sheetsIdRef = morningSheetsIdRef;
    survey = morningSurvey;
    promptRef = morningPromptRef;
  } else {
    sheetsIdRef = eveningSheetsIdRef;
    survey = eveningSurvey;
    promptRef = eveningPromptRef;
  }
  const { user } = useUserProvider();

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("name");
  console.log(userId);
  return (
    <div
      style={{
        padding: 10,
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: 10, marginTop: 20 }}>text entry</h2>
      <textarea
        type="text"
        name="description"
        value={newTextEntry}
        style={{ border: "1px solid black" }}
        onChange={(e) => handleNewTextEntry(e?.target?.value, setNewTextEntry)}
        className="textAreaBox"
      />
      <button
        style={{ marginLeft: 10 }}
        className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => {
          handleTextEntrySubmit(
            user,
            newTextEntry,
            setNewTextEntry,
            "/routines" + promptRef[promptCounter],
            setShowTextEntry,
            setShowSubmitted,
            showSubmitted,
            userId,
            sheetsIdRef[promptCounter]
          );
          nextPrompt();
          setMadeSelection(true);
          // toggle();
        }}
      >
        continue
      </button>
    </div>
  );
}
