import { onValue, ref } from "@firebase/database";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../common/firebaseConfig";
import { useUserProvider } from "./UserProvider";
//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?
export default function MindMapProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.
  const { user } = useUserProvider();
  const [showRecorder, setShowRecorder] = useState(false);
  const [showTextEntry, setShowTextEntry] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [newTextEntry, setNewTextEntry] = useState("");

  const [mindMaps, setMindMaps] = useState([]);
  const [mindMapKeys, setMindMapKeys] = useState([]);
  const [showUploading, setShowUploading] = useState(false);
  const [showUploadError, setShowUploadError] = useState(false);
  useEffect(() => {
    //log
    console.log(`user id has changed, ${user?.userId}`);
    if (!!user?.userId) {
      //   alert("user defined " + user?.userId);
      const mindMapRef = ref(db, "mindMaps/" + user?.userId);
      onValue(mindMapRef, (snapshot) => {
        const mindMapData = snapshot.val() || {};
        setMindMaps(Object.values(mindMapData));
        setMindMapKeys(Object.keys(mindMapKeys));
        console.log("mindmapdata from provider " + JSON.stringify(mindMapData));
        // console.log(JSON.stringify(userData));
        localStorage.setItem("mindMaps", JSON.stringify(mindMapData));
      });
    }
  }, [user?.userId]);

  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    showRecorder,
    setShowRecorder,
    showTextEntry,
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
    newTextEntry,
    setNewTextEntry,
    mindMaps,
    setMindMaps,
    showUploading,
    setShowUploading,
    showUploadError,
    setShowUploadError,

    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useMindMapProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}
