import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
//firebase config keys
const firebaseConfig = {
  apiKey: "AIzaSyCLB7NcT_pMjoaE8NANsjy-WEm3HyYKff4",
  authDomain: "inspired-331800.firebaseapp.com",
  projectId: "inspired-331800",
  storageBucket: "inspired-331800.appspot.com",
  messagingSenderId: "883879090126",
  appId: "1:883879090126:web:ae9a24b56b602f85c6bd9f",
  measurementId: "G-5GJJGJCCFY",
};
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

if (window.location.hostname.includes("localhost")) {
  //   auth.useEmulator("http://localhost:9099");
  //   firestore.useEmulator("localhost", 8080);
  //   functions.useEmulator("localhost", 5001);
}
