import { defaultStyles } from "react-modal";
import NavLinkButton from "./common/NavLinkButton";
import Navbar from "./common/Navbar";
import { useRoutinesProvider } from "./providers/RoutinesProvider";
import { useUserProvider } from "./providers/UserProvider";
import ContentWrapper from "./common/ContentWrapper";
import "./Routines.scss";

export default function RoutinesMorningIntro() {
  const { user } = useUserProvider();
  const { setPromptCounter } = useRoutinesProvider();

  return (
    <ContentWrapper
      subTitle="let's get our day started on the right foot!"
      title="morning routine"
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: 500,
            minHeight: 200,
            background: "#ffffff",
            margin: 10,
            position: "relative",
            padding: 10,
            width: "100vm",
          }}
        >
          <h3 className=" text-2xl">howdy and good morning!</h3>
          <p
            className=" text-1xl"
            style={{ fontStyle: "italic", marginTop: 10 }}
          >
            "It already happened. Trust the course, put in the work, and love
            the journey"
          </p>
          <iframe
            className="videoComponent"
            src="https://www.youtube.com/embed/eGguwYPC32I"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            style={{}}
          />
          <NavLinkButton
            buttonStyle={{ marginTop: 20 }}
            title={"get started"}
            link={"/routines-morning-prompts"}
            onClick={() => setPromptCounter(0)}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          />
        </div>
      </div>
    </ContentWrapper>
  );
}
