import React from "react";
import { Link } from "react-router-dom";

//creates nice looking button to be used accross app
const CommonLinkButton = ({
  title,
  link,
  buttonStyle = {},
  onClick,
  className,
}) => {
  //if no link then do not reutrn link
  if (!link) {
    return (
      <button
        style={{ ...styles["button"], ...buttonStyle }}
        onClick={onClick}
        className={className}
      >
        {/* // onClick={() => changeView("recorder")} */}
        {title}
      </button>
    );
  }
  //WHY IS THERE NO ELSE HERE?
  return (
    <Link to={link}>
      <button
        style={{ ...styles["button"], ...buttonStyle }}
        onClick={onClick}
        className={className}
      >
        {/* // onClick={() => changeView("recorder")} */}
        {title}
      </button>
    </Link>
  );
};

const styles = {
  button: {},
};

export default CommonLinkButton;
