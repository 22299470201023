import React, { useEffect, useMemo, useRef, useState } from "react";
import CommonLinkButton from "./common/CommonLinkButton";
import ContentWrapper from "./common/ContentWrapper";
import Navbar from "./common/Navbar";
import NavLinkButton from "./common/NavLinkButton";
import Entries from "./Entries";
import MindMapNewEntry from "./MindMapNewEntry";
import { useMindMapProvider } from "./providers/MindMapProvider";
import { useModalProvider } from "./providers/ModalProvider";
import { useUserProvider } from "./providers/UserProvider";

export default function MindMap() {
  const { user } = useUserProvider();
  const { mindMaps, setMindMap } = useMindMapProvider();
  const { setModalOpen } = useModalProvider();

  return (
    <ContentWrapper
      subTitle="inspiring thoughts, ideas, and notes go here"
      title="mind map"
    >
      <MindMapNewEntry />
    </ContentWrapper>
  );
}
