import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ContentWrapper from "./common/ContentWrapper";
import NavLinkButton from "./common/NavLinkButton";
import { useRoutinesProvider } from "./providers/RoutinesProvider";
import { useUserProvider } from "./providers/UserProvider";
import RoutinesRecorder from "./RoutinesRecorder";
import RoutinesTextEntry from "./RoutinesTextEntry";

export default function RoutinesMorningPrompts() {
  const { user } = useUserProvider();

  const {
    promptCounter,
    setPromptCounter,
    morningPromptRef,
    showRecorder,
    setShowRecorder,
    showTextEntry,
    setShowTextEntry,
    madeSelection,
    setMadeSelection,
    titles,
    setTitles,
    subTitles,
    setSubTitles,
    toggle,
    showSubmitted,
    showUploading,
  } = useRoutinesProvider();

  return (
    <ContentWrapper subTitle={subTitles} title={titles}>
      <div
        style={{
          display: "flex",
          placeContent: "center",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            placeContent: "center",
            alignContent: "center",
          }}
        >
          {showUploading && <p>saving your thought...please wait :)</p>}
        </div>
        {promptCounter < morningPromptRef.length && (
          <div>
            {!showTextEntry && !showUploading && <RoutinesRecorder />}
            {!!showTextEntry && !showUploading && <RoutinesTextEntry />}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              {!showRecorder && !madeSelection && !showUploading && (
                <a
                  style={{ textDecorationLine: "underline", color: "#3B82F6" }}
                  onClick={function (event) {
                    setShowTextEntry(false);
                    setShowRecorder(true);
                    setMadeSelection(true);
                  }}
                >
                  <button>speak your mind</button>
                </a>
              )}
              {!showTextEntry && !showUploading && (
                <a
                  style={{
                    textDecorationLine: "underline",
                    color: "#3B82F6",
                    marginTop: 20,
                  }}
                  onClick={function (event) {
                    setShowTextEntry(true);
                    setShowRecorder(false);
                    setMadeSelection(false);
                    // toggle();
                  }}
                >
                  <button>write your thought</button>
                </a>
              )}
            </div>
          </div>
        )}
        {promptCounter > 1 && (
          <div
            style={{
              alignItems: "center",
              alignText: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h3 style={{ marginBottom: 45 }}>you are awesome :)</h3>
          </div>
        )}
      </div>
    </ContentWrapper>
  );
}
