import MindMapChoices from "./MindMapChoices";
import MindMapInputs from "./MindMapInputs";
import { useMindMapProvider } from "./providers/MindMapProvider";
import "./MindMap.scss";

export default function MindMapNewEntry() {
  return (
    <div>
      <MindMapChoices />
      <MindMapInputs />
    </div>
  );
}
