import { auth, db } from "../components/common/firebaseConfig";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ref, update } from "firebase/database";

const signInWithGoogle = (setModalOpen, setUser) => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log({ result });
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const newUser = result.user;
      // console.log({ newUser });

      const newUserObject = {
        name: newUser.displayName,
        email: newUser.email,
        userId: newUser.uid,
        photo: newUser.photoURL,
      };
      //User ID is newUser.uid
      setUser(newUserObject);
      localStorage.setItem("user", JSON.stringify(newUserObject));
      setModalOpen(false);
      const userRef = ref(db, "users/" + newUserObject?.userId);
      update(userRef, newUserObject)
        .then(() => {
          console.log("success");
          console.log(newUserObject);
        })
        .catch((e) => console.log({ e }));
    })
    .catch((error) => {
      // Handle Errors here.
      console.log({ error });
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      alert("could not sign in " + error.code);
      // ...
    });
};

const signOut = (setUser) => {
  setUser(null);
  localStorage.removeItem("user");
  localStorage.removeItem("userId");
};

export { signInWithGoogle, signOut };
