import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { signOut } from "../../helpers/loginHelpers";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import NavLinkButton from "./NavLinkButton";
import lightbulb from "./lightbulb.svg";
import inspired from "./inspired.svg";

const navigation = [
  { name: "mind map", href: "/mind-map", current: false },
  { name: "routines", href: "routines", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const { user, setUser } = useUserProvider();
  const { setModalOpen } = useModalProvider();
  console.log("user photo" + user);
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a key="home" href="/">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={inspired}
                      alt="Workflow"
                    />
                  </a>
                </div>
                {!!user && (
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {!!user && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}

                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user?.photo}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => signOut(setUser)}
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
              {!user && (
                // <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                //   <div className="flex space-x-4">
                <div>
                  <NavLinkButton
                    title={"login"}
                    onClick={() => setModalOpen(true)}
                    buttonStyle={{
                      background: "transparent",
                      border: "white",
                      color: "white",
                      fontSize: 16,
                      width: 120,
                    }}
                  ></NavLinkButton>
                </div>
                // </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

// import { Fragment } from "react";
// import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { SearchIcon } from "@heroicons/react/solid";
// import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// };
// const navigation = [
//   { name: "Dashboard", href: "#", current: true },
//   { name: "Team", href: "#", current: false },
//   { name: "Projects", href: "#", current: false },
//   { name: "Calendar", href: "#", current: false },
//   { name: "Reports", href: "#", current: false },
// ];
// const userNavigation = [
//   { name: "Your Profile", href: "#" },
//   { name: "Settings", href: "#" },
//   { name: "Sign out", href: "#" },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// export default function BrandedNav() {
//   return (
//     <>
//       {/*
//         This example requires updating your template:

//         ```
//         <html class="h-full bg-gray-100">
//         <body class="h-full">
//         ```
//       */}
//       <div className="min-h-full">
//         <div className="bg-purple-500 pb-32">
//           <Disclosure
//             as="nav"
//             className="bg-purple-500 border-b border-purple-300 border-opacity-25 lg:border-none"
//           >
//             {({ open }) => (
//               <>
//                 <div className="max-w-8xl mx-auto px-2 sm:px-4 lg:px-8">
//                   <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-purple-500 lg:border-opacity-25">
//                     <div className="px-2 flex items-center lg:px-0">
//                       <div className="flex-shrink-0">
//                         <img
//                           className="block h-8 w-8"
//                           src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
//                           alt="Workflow"
//                         />
//                       </div>
//                       <div className="hidden lg:block lg:ml-10">
//                         <div className="flex space-x-4">
//                           {navigation.map((item) => (
//                             <a
//                               key={item.name}
//                               href={item.href}
//                               className={classNames(
//                                 item.current
//                                   ? "bg-purple-700 text-white"
//                                   : "text-white hover:bg-purple-500 hover:bg-opacity-75",
//                                 "rounded-md py-2 px-3 text-sm font-medium"
//                               )}
//                               aria-current={item.current ? "page" : undefined}
//                             >
//                               {item.name}
//                             </a>
//                           ))}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
//                       <div className="max-w-lg w-full lg:max-w-xs">
//                         <label htmlFor="search" className="sr-only">
//                           Search
//                         </label>
//                         <div className="relative text-gray-400 focus-within:text-gray-600">
//                           <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
//                             <SearchIcon
//                               className="h-5 w-5"
//                               aria-hidden="true"
//                             />
//                           </div>
//                           <input
//                             id="search"
//                             className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-500 focus:ring-white focus:border-white sm:text-sm"
//                             placeholder="Search"
//                             type="search"
//                             name="search"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="flex lg:hidden">
//                       {/* Mobile menu button */}
//                       <Disclosure.Button className="bg-purple-500 p-2 rounded-md inline-flex items-center justify-center text-purple-200 hover:text-white hover:bg-purple-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-500 focus:ring-white">
//                         <span className="sr-only">Open main menu</span>
//                         {open ? (
//                           <XIcon className="block h-6 w-6" aria-hidden="true" />
//                         ) : (
//                           <MenuIcon
//                             className="block h-6 w-6"
//                             aria-hidden="true"
//                           />
//                         )}
//                       </Disclosure.Button>
//                     </div>
//                     <div className="hidden lg:block lg:ml-4">
//                       <div className="flex items-center">
//                         <button
//                           type="button"
//                           className="bg-purple-500 flex-shrink-0 rounded-full p-1 text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-500 focus:ring-white"
//                         >
//                           <span className="sr-only">View notifications</span>
//                           <BellIcon className="h-6 w-6" aria-hidden="true" />
//                         </button>

//                         {/* Profile dropdown */}
//                         <Menu as="div" className="ml-3 relative flex-shrink-0">
//                           <div>
//                             <Menu.Button className="bg-purple-500 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-500 focus:ring-white">
//                               <span className="sr-only">Open user menu</span>
//                               <img
//                                 className="rounded-full h-8 w-8"
//                                 src={user.imageUrl}
//                                 alt=""
//                               />
//                             </Menu.Button>
//                           </div>
//                           <Transition
//                             as={Fragment}
//                             enter="transition ease-out duration-100"
//                             enterFrom="transform opacity-0 scale-95"
//                             enterTo="transform opacity-100 scale-100"
//                             leave="transition ease-in duration-75"
//                             leaveFrom="transform opacity-100 scale-100"
//                             leaveTo="transform opacity-0 scale-95"
//                           >
//                             <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
//                               {userNavigation.map((item) => (
//                                 <Menu.Item key={item.name}>
//                                   {({ active }) => (
//                                     <a
//                                       href={item.href}
//                                       className={classNames(
//                                         active ? "bg-gray-100" : "",
//                                         "block py-2 px-4 text-sm text-gray-700"
//                                       )}
//                                     >
//                                       {item.name}
//                                     </a>
//                                   )}
//                                 </Menu.Item>
//                               ))}
//                             </Menu.Items>
//                           </Transition>
//                         </Menu>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <Disclosure.Panel className="lg:hidden">
//                   <div className="px-2 pt-2 pb-3 space-y-1">
//                     {navigation.map((item) => (
//                       <Disclosure.Button
//                         key={item.name}
//                         as="a"
//                         href={item.href}
//                         className={classNames(
//                           item.current
//                             ? "bg-purple-700 text-white"
//                             : "text-white hover:bg-purple-500 hover:bg-opacity-75",
//                           "block rounded-md py-2 px-3 text-base font-medium"
//                         )}
//                         aria-current={item.current ? "page" : undefined}
//                       >
//                         {item.name}
//                       </Disclosure.Button>
//                     ))}
//                   </div>
//                   <div className="pt-4 pb-3 border-t border-purple-700">
//                     <div className="px-5 flex items-center">
//                       <div className="flex-shrink-0">
//                         <img
//                           className="rounded-full h-10 w-10"
//                           src={user.imageUrl}
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <div className="text-base font-medium text-white">
//                           {user.name}
//                         </div>
//                         <div className="text-sm font-medium text-purple-300">
//                           {user.email}
//                         </div>
//                       </div>
//                       <button
//                         type="button"
//                         className="ml-auto bg-purple-500 flex-shrink-0 rounded-full p-1 text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-500 focus:ring-white"
//                       >
//                         <span className="sr-only">View notifications</span>
//                         <BellIcon className="h-6 w-6" aria-hidden="true" />
//                       </button>
//                     </div>
//                     <div className="mt-3 px-2 space-y-1">
//                       {userNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as="a"
//                           href={item.href}
//                           className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-purple-500 hover:bg-opacity-75"
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))}
//                     </div>
//                   </div>
//                 </Disclosure.Panel>
//               </>
//             )}
//           </Disclosure>
//         </div>
//       </div>
//     </>
//   );
// }
