import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainRouter from "./components/MainRouter";
import UserProvider from "./components/providers/UserProvider";
import ModalProvider from "./components/providers/ModalProvider";
import MindMapProvider from "./components/providers/MindMapProvider";
import MediaProvider from "./components/providers/MediaProvider";
import RoutinesProvider from "./components/providers/RoutinesProvider";
import RecordingProvider from "./components/providers/RecordingProvider";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <MediaProvider>
    <UserProvider>
      <ModalProvider>
        <MindMapProvider>
          <RoutinesProvider>
            <RecordingProvider>
              <MainRouter />
            </RecordingProvider>
          </RoutinesProvider>
        </MindMapProvider>
      </ModalProvider>
    </UserProvider>
  </MediaProvider>,
  rootElement
);
