import React, { createContext, useContext, useEffect, useState } from "react";
//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?
export default function MediaProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 800px)").matches
  );

  useEffect(() => {
    const media = window.matchMedia("(min-width: 800px)");
    setIsDesktop(media.matches);
    const listener = () => {
      const newIsDesktop = media.matches;
      console.log(newIsDesktop ? "Size: Is Desktop" : "Size: Is Mobile");
      setIsDesktop(newIsDesktop);
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [isDesktop]);

  console.log({ isDesktop });
  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    isDesktop,
    setIsDesktop,
    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useMediaProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}

// 4) wrap index.js with provider
