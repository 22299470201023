import CommonLinkButton from "./common/CommonLinkButton";
import Navbar from "./common/Navbar";
import { useRoutinesProvider } from "./providers/RoutinesProvider";
import { useUserProvider } from "./providers/UserProvider";
import Entries from "./Entries";
import "./MindMap.scss";
import ContentWrapper from "./common/ContentWrapper";
import { useModalProvider } from "./providers/ModalProvider";

export default function Routines() {
  const { user } = useUserProvider();
  const { setModalOpen } = useModalProvider();
  const { setPromptCounter } = useRoutinesProvider();

  const routinesDailyStreamEntries = Object.values(
    user?.Routines?.dailyStream || {}
  );
  const routinesIntentionsEntries = Object.values(
    user?.Routines?.intentions || {}
  );

  const tabs = [
    { name: "daily streams", href: "#streams", current: false },
    { name: "intentions", href: "#intentions", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <ContentWrapper
        subTitle="incorporate gratitude and creativity into your day"
        title="routines"
      >
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 500,
                background: "#ffffff",
                margin: 10,
                position: "relative",
                padding: 10,
                width: "100vm",
              }}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <CommonLinkButton
                title={"morning boost"}
                link={"/routines-morning-prompts"}
                onClick={() => setPromptCounter(0)}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                buttonStyle={{ marginRight: 10 }}
              />
              <CommonLinkButton
                title={"evening charge"}
                link={"/routines-morning-intro"}
                onClick={() => setPromptCounter(0)}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
}
