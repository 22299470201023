export const saveAffirmationsToServer = (affirmations) => {
  // logic
};

export const fetchWithParams = async (url, params) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });

    if (response.status < 300) {
      console.log("error logged");
      return await response.json();
    } else {
      console.log("api fetch call failed", { url });
    }
  } catch (error) {
    console.log({ error });
    try {
    } catch (err) {
      console.log(err);
    }
  }
};
