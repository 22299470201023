import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import inspired from "./inspired.svg";
import NavLinkButton from "./NavLinkButton";
import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { signOut } from "../../helpers/loginHelpers";
import { useLocation } from "react-router-dom";

const userNavigation = [
  { name: "your profile", href: "#" },
  { name: "settings", href: "#" },
  { name: "sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContentWrapper({ children, title, subTitle }) {
  const location = useLocation();
  const { setModalOpen } = useModalProvider();
  const { user, setUser } = useUserProvider();

  const navigation = [
    { name: "mind map", href: "mind-map" },
    { name: "routines", href: "routines" },
  ];

  // useEffect(() => {
  //   HeaderView();
  // }, []);
  // function HeaderView() {
  //   console.log("path id" + location.pathname);
  // }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <div className="bg-blue-500 pb-32">
          <header className="py-10">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">{title}</h1>
              <p className="text-3l text-white" style={{ marginTop: 10 }}>
                {subTitle}
              </p>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="max-w-8xl mx-auto pb-12 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="bg-white rounded-lg shadow py-6 sm:px-6">
              {children}
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  );
}
