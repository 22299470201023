import { useMindMapProvider } from "./providers/MindMapProvider";
import { useRecordingProvider } from "./providers/RecordingProvider";
import { useSearchParams } from "react-router-dom";
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "./common/firebaseConfig";
import { ref, set } from "firebase/database";
import { fetchWithParams } from "../helpers/networkHelper";
import Timer from "./Timer";

export default function MindMapRecorder() {
  const {
    recordingData,
    isRecording,
    isRecordingDone,
    startRecord,
    stopRecord,
    audioRef,
    setIsRecordingDone,
    setRecordingData,
    toggle,
  } = useRecordingProvider();

  const {
    setShowSubmitted,
    setShowRecorder,
    setShowUploading,
    setShowUploadError,
  } = useMindMapProvider();

  //get user name for id field
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("name");

  const saveRecording = () => {
    setShowSubmitted(false);
    setShowUploading(true);
    setShowRecorder(false);
    if (!recordingData) {
      alert("please make an entry");
    } else {
      //define date for key
      const timeEntered = Date.now();
      //define firebase database path. with date (now) being the key
      const path = "mindMap/" + timeEntered;
      //goes to the path in the database
      const recordingDatabaseDateRef = ref(db, path);
      console.log("db ref: " + recordingDatabaseDateRef);
      //
      const recordingStorageDateRef = storageRef(storage, path + ".wav");

      //upload to storage
      //google function upload bytes that returns a promise(resolves as data or throws an error. Async function. )
      uploadBytes(recordingStorageDateRef, recordingData)
        //callack when promise resolves
        //snapshot is the data from the promise.
        .then(async (snapshot) => {
          console.log("we uploaded the snapshot!", { snapshot });
          //get download url from the snapshot
          //then is a new promise resolving
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            //set to database
            set(recordingDatabaseDateRef, {
              type: "recording",
              downloadURL: downloadURL,
              timeEntered: timeEntered,
              path,
              transcription: "processing...",
              user: userId,
            })
              .then(async () => {
                console.log("success, set recording");
                // do api call to our server
                fetchWithParams(
                  // "http://localhost:8081/transcribe",
                  "https://damp-everglades-91634.herokuapp.com/transcribe",
                  {
                    uri: "gs://inspired-331800.appspot.com/" + path + ".wav",
                    path: path,
                    recordingDatabaseDateRef: recordingDatabaseDateRef,
                    userId: userId,
                    timeEntered: timeEntered,
                    sheetsId: "mindMap!A:I",
                  }
                );
                setShowUploading(false);
                setShowSubmitted(true);
              })
              .catch((e) => {
                console.log({ e });
                setShowUploadError(true);
                console.log("upload error 1: " + setShowUploadError);
                setShowUploading(false);
              });
          });
        })
        .catch((e) => {
          console.log("upload error", { e });
          setShowUploadError(true);
          console.log("upload error 2: " + setShowUploadError);
          setShowUploading(false);
        });
    }
    setIsRecordingDone(false);
    setRecordingData(null);
  };
  //END RECORDING MODULE

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <Timer />
        <audio
          style={{ display: isRecordingDone ? "block" : "none" }}
          ref={audioRef}
          controls
          autoPlay={false}
          playsInline={true}
        ></audio>
        <div className="buttons" style={{ marginTop: 20 }}>
          {!isRecording && (
            <button
              style={{}}
              onClick={() => {
                startRecord();
                toggle();
              }}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {" "}
              {!isRecordingDone ? "start recording" : "record again"}
            </button>
          )}
          {isRecording && (
            <button
              style={{}}
              onClick={() => stopRecord()}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              stop recording
            </button>
          )}
          {/* hello */}
          {!!recordingData && (
            <button
              style={{ marginLeft: 10 }}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                saveRecording();
              }}
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
