import MindMapRecorder from "./MindMapRecorder";
import MindMapTextEntry from "./MindMapTextEntry";
import { useMindMapProvider } from "./providers/MindMapProvider";

export default function MindMapInputs() {
  const {
    showRecorder,
    setShowRecorder,
    showTextEntry,
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
  } = useMindMapProvider();

  return (
    <div>
      {/* mind map text entry */}
      <div>
        {!!showTextEntry && (
          <MindMapTextEntry
            setShowTextEntry={setShowTextEntry}
            setShowSubmitted={setShowSubmitted}
            showSubmitted={showSubmitted}
          />
        )}
      </div>

      {/* mind map recorder */}
      <div>{!!showRecorder && <MindMapRecorder />}</div>
    </div>
  );
}
