import React from "react";
import "../index.css";
import Home from "./Home";
import Counter from "./Counter";
import Modal from "./LoginModal";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Routines from "./Routines";
import RoutinesMorningPrompts from "./RoutinesMorningPrompts";
import RoutinesMorningIntro from "./RoutinesMorningIntro";
import MindMap from "./MindMap";
import RoutinesEveningPrompts from "./RoutinesEveningPrompts";
import PrivacyPolicy from "./PrivacyPolicy";
import ContactUs from "./ContactUs";
import Military from "./Military";
//highest level of state? or is that Provider?
export default function MainRouter() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="counter" element={<Counter />} />
          <Route path="modal" element={<Modal />} />
          <Route path="mind-map" element={<MindMap />} />
          <Route path="routines" element={<Routines />} />
          <Route path="military" element={<Military />} />

          <Route
            path="routines-morning-prompts"
            element={<RoutinesMorningPrompts />}
          />
          <Route
            path="routines-evening-prompts"
            element={<RoutinesEveningPrompts />}
          />
          <Route
            path="routines-morning-intro"
            element={<RoutinesMorningIntro />}
          />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="contact" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
      <Modal />
    </div>
  );
}
