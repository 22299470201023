import React, { createContext, useContext, useState } from "react";
//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?
export default function RoutinesProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.
  const [showRecorder, setShowRecorder] = useState(false);
  const [showTextEntry, setShowTextEntry] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [newTextEntry, setNewTextEntry] = useState("");
  const morningPromptRef = ["/intentions/", "/dailyStream/"];
  const morningSheetsIdRef = ["intentions!A:I", "daily stream!A:I"];
  const morningSurvey = ["intentions", "dailyStream"];
  const eveningPromptRef = ["/blessings/", "/gratitude/", "/curveball/"];
  const eveningSheetsIdRef = [
    "blessings!A:I",
    "gratitude!A:I",
    "curveball!A:I",
  ];
  const eveningSurvey = ["blessings", "gratitude", "curveball"];
  const [promptCounter, setPromptCounter] = useState(0);
  const [madeSelection, setMadeSelection] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [showUploading, setShowUploading] = useState(false);
  let titlesList = [];
  let subTitlesList = [];
  if (window.location.pathname === "/routines-morning-prompts") {
    titlesList = ["intentions", "affirmations", "all done!"];
    subTitlesList = [
      "what are 3 intentions for your day?",
      "What are your 3 affirmations for today?",
      "have a great day and see you later for your afternoon routine!",
    ];
  } else {
    titlesList = ["blessings", "manifest", "curveball", "all done!"];
    subTitlesList = [
      "list out three blessings from the day. say three things that went well today and why they went well.",
      "what are you manifesting? Close your eyes for a minute and imagine your dream future is your current reality. describe how it feels and how grateful you are! ",
      "what is something you learned today?",
      "sleep tight! see ya in the AM!",
    ];
  }

  const [titles, setTitles] = useState(titlesList[promptCounter]);
  const [subTitles, setSubTitles] = useState(subTitlesList[promptCounter]);

  const nextPrompt = () => {
    const newPromptCount = promptCounter + 1;
    setPromptCounter(newPromptCount);
    setTitles(titlesList[promptCounter + 1]);
    setSubTitles(subTitlesList[promptCounter + 1]);
    console.log(titles);
    console.log(promptCounter);
  };

  const [isActive, setIsActive] = useState(false);

  function toggle() {
    setSeconds(0);
    setIsActive(!isActive);
  }
  function reset() {
    setSeconds(0);
    setIsActive(false);
  }
  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    showRecorder,
    setShowRecorder,
    showTextEntry,
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
    newTextEntry,
    setNewTextEntry,
    morningPromptRef,
    promptCounter,
    setPromptCounter,
    nextPrompt,
    madeSelection,
    setMadeSelection,
    titles,
    setTitles,
    subTitles,
    setSubTitles,
    isActive,
    setIsActive,
    toggle,
    seconds,
    setSeconds,
    reset,
    morningSheetsIdRef,
    morningSurvey,
    eveningPromptRef,
    eveningSheetsIdRef,
    eveningSurvey,
    showUploading,
    setShowUploading,
    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useRoutinesProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}
