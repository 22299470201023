import { db } from "../components/common/firebaseConfig";
import { ref, set } from "firebase/database";
import Moment from "moment";

export const handleNewTextEntry = (newTextEntry, setNewTextEntry) => {
  console.log("handleNewTextEntry", { newTextEntry });
  setNewTextEntry(newTextEntry);
};
//
export const handleTextEntrySubmit = (
  user,
  newTextEntry,
  setNewTextEntry,
  pathText,
  setShowTextEntry,
  setShowSubmitted,
  showSubmitted,
  userId,
  sheetsId
) => {
  //if blank throw error
  if (!newTextEntry) {
    alert("please make an entry");
  } else {
    //define date for key
    const timeEntered = Date.now();
    //define firebase database path. with date (now) being the key
    const path = pathText + "/" + timeEntered;
    //goes to the path in the database
    const TextEntryDateRef = ref(db, path);
    //set to database
    set(TextEntryDateRef, {
      type: "textEntry",
      text: newTextEntry,
      timeEntered: timeEntered,
      path,
      user: userId,
    })
      .then(() => {
        console.log("success, set text entry");
        setNewTextEntry("");
        setShowTextEntry(false);
        setShowSubmitted(true);
        console.log(showSubmitted);
      })
      .then(async () => {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: "React POST Request Example" }),
        };
        const sheetsResponse = await fetch(
          "https://us-central1-inspired-331800.cloudfunctions.net/testsheetwrite?timeEntered=" +
            Moment(timeEntered).format("dddd, M/D/YY h:mmA").toLowerCase() +
            "&recordingUrl=0" +
            "&path=" +
            path +
            "&userId=" +
            userId +
            "&backUpText=" +
            newTextEntry +
            "&url=0&location=nill&sentiment=0&transcription=0" +
            "&sheetsId=" +
            sheetsId,
          requestOptions
        );

        const data = await sheetsResponse.json();
        console.log(sheetsResponse);
      })
      .catch((e) => console.log({ e }));
  }
};
