//import Text Entry Helper Files
import {
  handleNewTextEntry,
  handleTextEntrySubmit,
} from "../helpers/textEntryHelpers";
import { useState } from "react";
import { useUserProvider } from "./providers/UserProvider";
import { useMindMapProvider } from "./providers/MindMapProvider";
import "./MindMap.scss";
import { useSearchParams } from "react-router-dom";

export default function MindMapTextEntry() {
  const {
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
    newTextEntry,
    setNewTextEntry,
  } = useMindMapProvider();

  const { user } = useUserProvider();

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("name");
  console.log(userId);

  return (
    <div
      style={{
        padding: 10,
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: 10 }}>text entry</h2>
      <textarea
        type="text"
        name="description"
        value={newTextEntry}
        onChange={(e) => handleNewTextEntry(e?.target?.value, setNewTextEntry)}
        className="textAreaBox"
        style={{ border: "1px solid black" }}
      />
      <button
        style={{}}
        onClick={() =>
          handleTextEntrySubmit(
            user,
            newTextEntry,
            setNewTextEntry,
            "mindMap",
            setShowTextEntry,
            setShowSubmitted,
            showSubmitted,
            userId,
            "mindMap!A:I"
          )
        }
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        submit
      </button>
    </div>
  );
}
