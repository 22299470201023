import { useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import { db, storage } from "./common/firebaseConfig";
import { ref, set, update } from "firebase/database";
import { fetchWithParams } from "../helpers/networkHelper";

import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { useUserProvider } from "./providers/UserProvider";
import { useRoutinesProvider } from "./providers/RoutinesProvider";
import { useSearchParams } from "react-router-dom";
import RoutinesTimer from "./RoutinesTimer";
import toWav from "audiobuffer-to-wav";
import xhr from "xhr";
import NoSleep from "nosleep.js";

export default function RoutinesRecorder(props) {
  var audioContext = new AudioContext();
  var noSleep = new NoSleep();

  const {
    setShowRecorder,
    setShowSubmitted,
    morningPromptRef,
    eveningPromptRef,
    promptCounter,
    nextPrompt,
    toggle,
    reset,
    morningSheetsIdRef,
    morningSurvey,
    eveningSheetsIdRef,
    eveningSurvey,
    showUploading,
    setShowUploading,
  } = useRoutinesProvider();
  let sheetsIdRef = [];
  let survey = [];
  let promptRef = [];
  if (window.location.pathname === "/routines-morning-prompts") {
    sheetsIdRef = morningSheetsIdRef;
    survey = morningSurvey;
    promptRef = morningPromptRef;
  } else {
    sheetsIdRef = eveningSheetsIdRef;
    survey = eveningSurvey;
    promptRef = eveningPromptRef;
  }
  console.log("survey is: " + survey[0]);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [recorder, setRecorder] = useState(false);
  const [recordingData, setRecordingData] = useState(null);
  const audioRef = useRef(null);

  const { user } = useUserProvider();

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("name");
  console.log(userId);

  //Start Recording
  const startRecord = () => {
    //Reset recording done and recording data for re-record functionality.
    setIsRecordingDone(false);
    setRecordingData(null);
    console.log("recording started");
    setIsRecording(true);
    //capture recroding funciton

    //QUESTION HOW DO WE HAVE A FUNCTION IN THE FUNCTION WE ARE CALLING (DEFINED BELOW as a callback function)
    captureRecording(function (microphone) {
      //set the audio components
      audioRef.current.muted = true;
      audioRef.current.volume = 0;
      audioRef.current.srcObject = microphone;

      //define recording constructs using RecordRTC
      var newRecorder = RecordRTC(microphone, {
        type: "audio",
        mimeType: "audio/wav",
        // recorderType: "MediaStreamRecorder",
      });

      //function to start recording
      newRecorder.startRecording();

      // release microphone on stopRecording

      //setting the microphone property of microphone = microphone (which is called in the above function)
      newRecorder.microphone = microphone;
      console.log(audioRef.current);
      console.log("Microphone: " + microphone);
      setRecorder(newRecorder);
      console.log("NewRecorder: " + newRecorder.microphone.type);
      toggle();
    });
  };

  const stopRecord = () => {
    toggle();
    console.log("stop recording pressed", { recorder });
    setIsRecording(false);
    //QUESTION? What's up with the bool? what are all the different vars in console.log?
    recorder?.stopRecording?.(stopRecordingCallback);
  };

  //Get File Name
  function getFileName(fileExtension) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    return (
      "Inspired" +
      year +
      month +
      date +
      "-" +
      getRandomString() +
      "." +
      fileExtension
    );
  }

  function getRandomString() {
    if (
      window.crypto &&
      window.crypto.getRandomValues &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      var a = window.crypto.getRandomValues(new Uint32Array(3)),
        token = "";
      for (var i = 0, l = a.length; i < l; i++) {
        token += a[i].toString(36);
      }
      return token;
    } else {
      return (Math.random() * new Date().getTime())
        .toString(36)
        .replace(/\./g, "");
    }
  }

  function replaceAudio(src) {
    var newAudio = document.createElement("audio");
  }

  const stopRecordingCallback = () => {
    console.log("stopRecordingCallback fired");
    replaceAudio(URL.createObjectURL(recorder.getBlob()));

    audioRef.current.muted = false;
    audioRef.current.volume = 1;
    audioRef.current.srcObject = null;
    //save data into a blob
    const newDataBlob = recorder.getBlob();
    //creates file and names it using GetFileName function above.
    var file = new File([newDataBlob], "newaudiofile.wav", {
      type: newDataBlob.type || "audio/wav",
    });
    //set file path of audio
    audioRef.current.src = URL.createObjectURL(file);
    console.log("audio file URL:" + audioRef.current.src);

    xhr(
      {
        uri: audioRef.current.src,
        responseType: "arraybuffer",
      },
      function (err, body, resp) {
        if (err) throw err;
        // decode the MP3 into an AudioBuffer
        audioContext.decodeAudioData(resp, function (buffer) {
          // encode AudioBuffer to WAV
          var wav = toWav(buffer);
          console.log(wav);
          //pass blob to parent setter
          setRecordingData(wav);
          // do something with the WAV ArrayBuffer ...
        });
      }
    );

    // //pass blob to parent setter
    // setRecordingData(file);

    recorder.microphone.stop();
    recorder.destroy();
    setRecorder(null);
    setIsRecordingDone(true);
    // audioRef.current.play();
  };

  //Get User Media
  const captureRecording = (callback) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (microphone) {
        callback(microphone);
      })
      .catch(function (error) {
        console.log(
          "Unable to capture your audio. Please refresh and give microphone access"
        );
        console.error(error);
      });
  };

  const saveRecording = () => {
    //reset timer
    reset();
    setShowSubmitted(false);
    setShowUploading(true);
    if (!recordingData) {
      alert("please make an entry");
    } else {
      //define date for key
      const timeEntered = Date.now();
      //define firebase database path. with date (now) being the key
      const path = "/routines" + promptRef[promptCounter] + timeEntered;
      console.log(promptRef[promptCounter]);
      //goes to the path in the database
      const recordingDatabaseDateRef = ref(db, path);
      //
      const recordingStorageDateRef = storageRef(storage, path + ".wav");

      //upload to storage
      //google function upload bytes that returns a promise(resolves as data or throws an error. Async function. )
      uploadBytes(recordingStorageDateRef, recordingData)
        //callack when promise resolves
        //snapshot is the data from the promise.
        .then((snapshot) => {
          console.log("we uploaded the snapshot!", { snapshot });
          //get download url from the snapshot
          //then is a new promise resolving
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            //set to database
            set(recordingDatabaseDateRef, {
              type: "recording",
              URL: downloadURL,
              timeEntered: timeEntered,
              path,
              transcription: "processing...",
              user: userId,
            })
              .then(async () => {
                console.log("success, set recording");

                // do api call to our server
                fetchWithParams(
                  // "http://localhost:8081/transcribe",
                  "https://damp-everglades-91634.herokuapp.com/transcribe",
                  {
                    uri: "gs://inspired-331800.appspot.com" + path + ".wav",
                    path: path,
                    recordingDatabaseDateRef: recordingDatabaseDateRef,
                    userId: userId,
                    timeEntered: timeEntered,
                    sheetsId: sheetsIdRef[promptCounter],
                    survey: morningSurvey[promptCounter],
                  }
                );
                setShowUploading(false);
                setShowSubmitted(true);
                nextPrompt();

                // gs://inspired-331800.appspot.com

                // setNewMindEntry("");
              })
              .catch((e) => console.log({ e }));
          });
        })
        .catch((e) => {
          console.log("upload error", { e });
        });
    }
    setIsRecordingDone(false);
    setRecordingData(null);
    setShowRecorder(false);
  };
  //END RECORDING MODULE

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <RoutinesTimer />
        <audio
          style={{ display: isRecordingDone ? "block" : "none" }}
          ref={audioRef}
          controls
          autoPlay={false}
          playsInline={true}
        ></audio>
        <div className="buttons" style={{ marginTop: 20 }}>
          {!isRecording && (
            <button
              style={{}}
              onClick={() => {
                startRecord();
                document.addEventListener(
                  "click",
                  function enableNoSleep() {
                    document.removeEventListener("click", enableNoSleep, false);
                    noSleep.enable();
                  },
                  false
                );
              }}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {!isRecordingDone ? "start recording" : "record again"}
            </button>
          )}
          {isRecording && (
            <button
              style={{}}
              onClick={() => stopRecord()}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              stop recording
            </button>
          )}
          {!!recordingData && (
            <button
              style={{ marginLeft: 10 }}
              className="recordButton inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                saveRecording();
              }}
            >
              continue
            </button>
          )}
        </div>
      </div>
    </>
  );
}
