import React, { useMemo, useState, useEffect } from "react";
import { useUserProvider } from "./providers/UserProvider";
import { useMediaProvider } from "./providers/MediaProvider";

import "./Entries.scss";
import Entry from "./Entry";
import { slice, concat } from "lodash";

export default function Entries({ entries = [] }) {
  console.log("entries " + entries);
  const { user } = useUserProvider();
  const { isDesktop } = useMediaProvider();
  const [searchText, setSearchText] = useState("");

  const filterEntries = entries?.filter((entry) => {
    return (
      entry?.text?.includes(searchText) ||
      entry?.transcription?.includes(searchText) ||
      !searchText
    );
  });
  //load more stuff
  console.log("DATA" + filterEntries);
  const [limit, setLimit] = useState(isDesktop ? 4 : 1);
  const showMoreButton = limit < filterEntries.length;

  useEffect(() => {
    if (limit < 5) {
      return setLimit(isDesktop ? 4 : 1);
    }
  }, [isDesktop]);

  //end load more stuff
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="entriesContainer"
    >
      <input
        placeholder={"search"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{
          maxWidth: 500,
          width: "80%",
          borderRadius: 10,
          border: "1px solid gray",
          padding: 10,
          boxShadow: "3px 2px 2px -2px rgba(0,0,0,0.48)",
          marginBottom: 20,
        }}
      />
      <div style={{ display: "flex", justifyContent: "right", width: "80%" }}>
        {showMoreButton && (
          <button onClick={() => setLimit(filterEntries.length + 10)}>
            {" "}
            show all{" "}
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          minHeight: 300,
          // maxWidth: "100vw",
          // justifyContent: "left",
          // width: "80%",
          marginLeft: "5%",
          marginRight: "5%",
          width: "90%",
          justifyContent: "center",
        }}
        className="mapsContainer"
      >
        {filterEntries
          ?.sort((a, b) => {
            console.log({ a, b });
            return a?.timeEntered < b?.timeEntered ? 1 : -1;
          })
          ?.map((entry, index) => {
            if (index > limit - 1) {
              return;
            }
            return (
              <Entry
                entry={entry}
                key={entry?.timeEntered}
                ID={`${entry?.timeEntered}`}
              />
            );
          })}
      </div>
    </div>
  );
}

const EntriesWrapper = ({ entries }) => {
  return useMemo(() => <Entries entries={entries} />, []);
};

// import React, { useMemo, useState } from "react";
// import { useUserProvider } from "./providers/UserProvider";
// import "./Entries.scss";
// import Entry from "./Entry";
// import { slice, concat } from "lodash";

// export default function Entries({ entries = [] }) {
//   const { user } = useUserProvider();
//   const [searchText, setSearchText] = useState("");

//   const filterEntries = entries?.filter((entry) => {
//     return (
//       entry?.text?.includes(searchText) ||
//       entry?.transcription?.includes(searchText) ||
//       !searchText
//     );
//   });
//   const LENGTH = filterEntries?.length;
//   const LIMIT = 4;
//   //hooks for show more
//   const [showMore, setShowMore] = useState(true);
//   const [index, setIndex] = useState(LIMIT);

//   //variables for show more

//   console.log("len" + filterEntries.length);
//   const [list, setList] = useState(slice(filterEntries, 0, LIMIT));
//   console.log("list" + slice(filterEntries, 0, 10));

//   const loadMore = () => {
//     const newIndex = LENGTH;
//     const newShowMore = newIndex < LENGTH - 1;
//     const newList = concat(list, slice(filterEntries, index, newIndex));
//     setIndex(newIndex);
//     setList(newList);
//     setShowMore(newShowMore);
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//       }}
//       className="entriesContainer"
//     >
//       <input
//         placeholder={"search"}
//         value={searchText}
//         onChange={(e) => setSearchText(e.target.value)}
//         style={{
//           maxWidth: 500,
//           width: "80%",
//           borderRadius: 10,
//           border: "1px solid gray",
//           padding: 10,
//           boxShadow: "3px 2px 2px -2px rgba(0,0,0,0.48)",
//           marginBottom: 20,
//         }}
//       />
//       <div style={{ display: "flex", justifyContent: "right", width: "80%" }}>
//         {showMore && (
//           <button onClick={() => loadMore()} className="text-blue-500">
//             {" "}
//             show all{" "}
//           </button>
//         )}
//       </div>
//       <div
//         style={{
//           display: "flex",
//           flexWrap: "wrap-reverse",
//           minHeight: 300,
//           // maxWidth: "100vw",
//           // justifyContent: "left",
//           // width: "80%",
//           marginLeft: "5%",
//           marginRight: "5%",
//           width: "90%",
//           justifyContent: "center",
//         }}
//         className="mapsContainer"
//       >
//         {list?.map((entry, index) => {
//           return (
//             <Entry
//               entry={entry}
//               key={entry?.timeEntered}
//               ID={`${entry?.timeEntered}`}
//             />
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// const EntriesWrapper = ({ entries }) => {
//   return useMemo(() => <Entries entries={entries} />, []);
// };
