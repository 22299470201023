import { useMediaProvider } from "./providers/MediaProvider";
import { useMindMapProvider } from "./providers/MindMapProvider";
import { useRecordingProvider } from "./providers/RecordingProvider";

import { useModalProvider } from "./providers/ModalProvider";
import "./MindMap.scss";
import NoSleep from "nosleep.js";

export default function MindMapChoices() {
  var noSleep = new NoSleep();

  const {
    showRecorder,
    setShowRecorder,
    showTextEntry,
    setShowTextEntry,
    showSubmitted,
    setShowSubmitted,
    showUploading,
    setShowUploading,
    showUploadError,
    setShowUploadError,
  } = useMindMapProvider();

  const { startRecord, toggle } = useRecordingProvider();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: "#ffffff",
        position: "relative",
      }}
      className="choicesContainer"
    >
      {/* display option buttons. Conditional on what has been clicked */}
      {showSubmitted && <p style={{ marginBottom: 10 }}>saved!</p>}
      {showUploading && (
        <p style={{ marginBottom: 10 }}>saving your thought...please wait :)</p>
      )}
      {showUploadError && (
        <p style={{ marginBottom: 10 }}>
          we're sorry, there was an error uploading your recording. please text
          Alex at 407-758-0327 and we may be able to recover your recording.
          Otherwise, please refresh and try again.
        </p>
      )}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {!showRecorder && (
          <button
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={function (event) {
              setShowTextEntry(false);
              setShowRecorder(true);
              setShowSubmitted(false);
              setShowUploading(false);
              setShowUploadError(false);
              startRecord();
              console.log("touched");
              document.addEventListener(
                "click",
                function enableNoSleep() {
                  document.removeEventListener("click", enableNoSleep, false);
                  noSleep.enable();
                },
                false
              );
            }}
          >
            speak your mind
          </button>
        )}
        {!showTextEntry && (
          <button
            style={{ marginLeft: 10 }}
            onClick={function (event) {
              setShowTextEntry(true);
              setShowRecorder(false);
              setShowSubmitted(false);
              setShowUploading(false);
              setShowUploadError(false);
            }}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            write your thought
          </button>
        )}
      </div>
    </div>
  );
}
