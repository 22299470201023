import React, { useRef, createContext, useContext, useState } from "react";
import RecordRTC from "recordrtc";
import { useUserProvider } from "./UserProvider";
import toWav from "audiobuffer-to-wav";
import xhr from "xhr";
var audioContext = new AudioContext();

//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?
export default function RecordingProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.

  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [recorder, setRecorder] = useState(false);
  const [recordingData, setRecordingData] = useState(null);
  const [setShowRecorder, setShowSubmitted] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const audioRef = useRef(null);

  const [isActive, setIsActive] = useState(false);

  function toggle() {
    setSeconds(0);
    setIsActive(!isActive);
  }
  function reset() {
    setSeconds(0);
    setIsActive(false);
  }

  const startRecord = () => {
    //Reset recording done and recording data for re-record functionality.
    setIsRecordingDone(false);
    setRecordingData(null);
    console.log("recording started");
    setIsRecording(true);
    //capture recording funciton

    //QUESTION HOW DO WE HAVE A FUNCTION IN THE FUNCTION WE ARE CALLING (DEFINED BELOW as a callback function)
    captureRecording(function (microphone) {
      //set the audio components
      audioRef.current.muted = true;
      audioRef.current.volume = 0;
      audioRef.current.srcObject = microphone;

      //define recording constructs using RecordRTC
      var newRecorder = RecordRTC(microphone, {
        type: "audio",
        mimeType: "audio/wav",
        // recorderType: "MediaStreamRecorder",
        checkForInactiveTracks: true,
      });

      //function to start recording
      newRecorder.startRecording();

      // release microphone on stopRecording

      //setting the microphone property of microphone = microphone (which is called in the above function)
      newRecorder.microphone = microphone;
      recorder?.stopRecording?.(stopRecordingCallback);
      console.log("recorder: " + recorder);
      console.log(audioRef.current);
      console.log("Microphone: " + microphone);
      setRecorder(newRecorder);
      console.log("NewRecorder: " + newRecorder.microphone.type);
      //toggle timer
      toggle();
    });
  };

  const stopRecord = () => {
    toggle();
    console.log("stop recording pressed", { recorder });
    setIsRecording(false);
    //QUESTION? What's up with the bool? what are all the different vars in console.log?
    recorder?.stopRecording?.(stopRecordingCallback);
  };

  function getFileName(fileExtension) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    return (
      "Inspired" +
      year +
      month +
      date +
      "-" +
      getRandomString() +
      "." +
      fileExtension
    );
  }

  function getRandomString() {
    if (
      window.crypto &&
      window.crypto.getRandomValues &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      var a = window.crypto.getRandomValues(new Uint32Array(3)),
        token = "";
      for (var i = 0, l = a.length; i < l; i++) {
        token += a[i].toString(36);
      }
      return token;
    } else {
      return (Math.random() * new Date().getTime())
        .toString(36)
        .replace(/\./g, "");
    }
  }

  function replaceAudio(src) {
    var newAudio = document.createElement("audio");
  }

  const stopRecordingCallback = () => {
    console.log("stopRecordingCallback fired");
    replaceAudio(URL.createObjectURL(recorder.getBlob()));

    audioRef.current.muted = false;
    audioRef.current.volume = 1;
    audioRef.current.srcObject = null;
    //save data into a blob
    const newDataBlob = recorder.getBlob();
    //creates file and names it using GetFileName function above.
    var file = new File([newDataBlob], "newaudiofile.wav", {
      type: newDataBlob.type || "audio/wav",
    });
    //set file path of audio
    audioRef.current.src = URL.createObjectURL(file);
    console.log("audio file URL:" + audioRef.current.src);

    xhr(
      {
        uri: audioRef.current.src,
        responseType: "arraybuffer",
      },
      function (err, body, resp) {
        if (err) throw err;
        // decode the MP3 into an AudioBuffer
        audioContext.decodeAudioData(resp, function (buffer) {
          // encode AudioBuffer to WAV
          var wav = toWav(buffer);
          console.log(wav);
          //pass blob to parent setter
          setRecordingData(wav);
          // do something with the WAV ArrayBuffer ...
        });
      }
    );

    // //pass blob to parent setter
    // setRecordingData(file);

    recorder.microphone.stop();
    recorder.destroy();
    setRecorder(null);
    setIsRecordingDone(true);
    // audioRef.current.play();
  };

  //Get User Media
  const captureRecording = (callback) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (microphone) {
        callback(microphone);
      })
      .catch(function (error) {
        console.log(
          "Unable to capture your audio. Please refresh and give microphone access"
        );
        console.error(error);
      });
  };

  const { user } = useUserProvider();

  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    recordingData,
    isRecording,
    isRecordingDone,
    startRecord,
    stopRecord,
    audioRef,
    setIsRecordingDone,
    setRecordingData,
    setShowRecorder,
    setShowSubmitted,
    isActive,
    setIsActive,
    toggle,
    reset,
    seconds,
    setSeconds,
    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useRecordingProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}
