import React, { createContext, useContext, useState } from "react";
//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?
export default function ModalProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.

  const [modalOpen, setModalOpen] = useState(false);

  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    modalOpen,
    setModalOpen,

    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useModalProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}
