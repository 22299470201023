import React, { createContext, useContext, useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { db } from "../common/firebaseConfig";
//what is stateContext?
export const StateContext = createContext(null);
//why do we pass through props?

const getInitialUser = () => {
  var newUser = localStorage.getItem("user");
  if (newUser) {
    // console.log("User found" + newUser)
    try {
      //add to user hook and login
      newUser = JSON.parse(newUser);
    } catch (e) {
      alert("error");
      localStorage.clear();
      console.log({ e });
    }
  }
  return newUser;
};

export default function UserProvider(props) {
  //1. SECTION FOR HOOKS TO BE USED GLOBALLY.
  const [user, setUser] = useState(getInitialUser());

  //set db user info to state
  //set state of user
  useEffect(() => {
    console.log(`user id has changed, ${user?.userId}`);
    if (!!user?.userId) {
      //   alert("user defined " + user?.userId);
      const userRef = ref(db, "users/" + user?.userId);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        setUser(userData);
        // console.log(JSON.stringify(userData));
        localStorage.setItem("user", JSON.stringify(userData));
      });
    }
  }, [user?.userId]);

  //create hook to carry mind entry variables
  //SECTION FOR EXPORTED STATE HOOKS (GETTERS AND SETTERS)
  const contextValue = {
    user: user,
    setUser,
    // 2) add getter setter here
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

// 3) import getter setter in any file with
// const {getter, setter, ....} = useProvider()

//What does use Provider do?
export function useUserProvider() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useProvider must be used within Provider");
  }
  return context;
}
