import { useRef, useState } from "react";
import Moment from "moment";
import { db } from "./common/firebaseConfig";
import { ref, set, update } from "firebase/database";
import "./Entries.scss";
import { XIcon, PlayIcon } from "@heroicons/react/outline";

//PAST ENTRIES COMPONENT
export default function Entry({ entry, ID }) {
  const [showRecording, setShowRecording] = useState(false);
  // const [isAudio, setIsAudio] = useState(false);

  // destructure entry object.
  const { URL, timeEntered, path, text, type, transcription } = entry;
  // console.log(entry);
  const audioRef = useRef(null);

  const playRecording = () => {
    setShowRecording(true);
    audioRef.current.muted = false;
    audioRef.current.volume = 1;
    audioRef.current.srcObject = null;
    audioRef.current.play();

    // example 12.3234 seconds
    audioRef.current.addEventListener("loadedmetadata", (e) => {
      console.log(e.target.duration);
    });
  };

  //update transcription
  const updateTranscription = (e, path) => {
    const data = {
      transcription: e,
    };
    console.log({ data, path });
    const TextEntryDateRef = ref(db, path);

    update(TextEntryDateRef, data)
      .then(() => {
        console.log("success, set text entry");
      })
      .catch((e) => console.log({ e }));
  };

  //update transcription
  const updateText = (e, path) => {
    const data = {
      text: e,
    };
    console.log({ data, path });
    const TextEntryDateRef = ref(db, path);

    update(TextEntryDateRef, data)
      .then(() => {
        console.log("success, set text entry");
      })
      .catch((e) => console.log({ e }));
  };
  return (
    <>
      <div
        id={ID}
        style={{ position: "relative", textAlign: "center" }}
        className="EntryContainer bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
      >
        <div className="px-4 py-5 sm:px-6">
          <button
            style={{ position: "absolute", top: 0, right: 0, padding: 5 }}
            onClick={() => {
              if (
                window.confirm("are you sure you want to delete this entry?")
              ) {
                const pathRf = ref(db, path);
                set(pathRf, null);
              }
            }}
          >
            <XIcon className="h-6 w-6 text-blue-200" aria-hidden="true" />
          </button>

          <p className="text-blue-500">
            {Moment(timeEntered).format("dddd, M/D/YY h:mmA").toLowerCase()}
          </p>
          {/* We use less vertical padding on card headers on desktop than on body sections */}
        </div>
        <div className="px-4 py-5 sm:p-6">
          <textarea
            style={{
              display: type == "textEntry" ? "" : "none",
              height: 100,
              maxWidth: "100%",
              border: "none",
              resize: "none",
            }}
            value={text}
            type={"textarea"}
            cols="40"
            rows="5"
            onChange={(e) => updateText(e?.target?.value, path)}
          />{" "}
          <textarea
            style={{
              display: type != "textEntry" ? "" : "none",
              height: 100,
              maxWidth: "100%",
              border: "none",
              resize: "none",
            }}
            value={transcription}
            type={"textarea"}
            cols="40"
            rows="5"
            onChange={(e) => updateTranscription(e?.target?.value, path)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              height: 10,
              width: "100%",
              textAlign: "left",
              paddingTop: 20,
              position: "relative",
              flexDirection: "row",
            }}
          >
            {type != "textEntry" && (
              <button style={{}} onClick={playRecording}>
                <PlayIcon
                  className="h-6 w-6 text-blue-400"
                  aria-hidden="true"
                />
              </button>
            )}
            <audio
              style={{
                display: !!showRecording ? "block" : "none",
                postion: "absolute",
                top: 10,
                right: 20,
                width: "100%",
                marginTop: -15,
                marginLeft: 5,
              }}
              src={URL}
              ref={audioRef}
              controls
              autoPlay={false}
              playsInline={true}
            ></audio>
          </div>
        </div>
        <div className="px-4 py-4 sm:px-6">
          {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
        </div>
      </div>
    </>
  );
}
