import Iframe from "react-iframe";
import Background from "../iphone2.svg";

export default function Military() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "relative",
            background: "white",
            width: 375,
            height: 600,
          }}
        >
          <Iframe
            url="https://withkoji.com/@alexh/m3ac"
            width="100%"
            height="600px"
            id="myId"
            className="myClassname"
            display="initial"
            position="absolute"
          />
          <div
            style={{
              height: 50,
              width: "100%",
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          {/* hi */}
          <a href="sms:+14248771598&body=hey! I%27m interested in signing up for inspired! (military family)">
            <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-small rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {" "}
              Sign Up
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
