export default function ContactUs() {
  return (
    <>
      <h1 style={{ fontSize: 24 }}>Contact Us</h1>{" "}
      <h2>
        If you have any question, concerns, or feedback please do not hesitate
        to reach out:{" "}
      </h2>
      <ul>
        <li>
          <b>email:</b> alex@myinspiredself.com
        </li>
        <li>
          <b>phone:</b> +1 (407) 758-0327
        </li>
      </ul>
    </>
  );
}
